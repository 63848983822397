export default {
  namespaced: true,
  state: {
    unionSelected: null,
    unions: [],
  },
  getters: {
    currentUnion: state => state.unionSelected,
    unionList: state => state.unions,
  },
  mutations: {
    UPDATE_SELECTED_UNION(state, val) {
      state.unionSelected = val
    },
    UPDATE_UNION_LIST(state, val) {
      state.unions = val
    },
  },
  actions: {},
}
