import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import * as XLSX from 'xlsx'
import VueMask from 'v-mask'
import pdfMake from 'pdfmake/build/pdfmake'
import axios from 'axios'
import pdfFonts from '../public/vfs_fonts'
import router from './router'
import store from './store'
import App from './App.vue'
import Helper from './Helpers.vue'

// Global Components
import './global-components'
import './helper'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import jwtDefaultConfig from './@core/auth/jwt/jwtDefaultConfig'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueMask)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  Arial: {
    normal: 'Arial Unicode MS.ttf',
    bold: 'Arial Unicode MS.ttf',
  },
}
Vue.config.productionTip = false
window.XLSX = XLSX
window.pdfMake = pdfMake

axios.interceptors.request.use(request => {
  const token = window.ManMudra.getters.accessToken()

  if (window.ManMudra.getters.userInfo() && token && !request.headers.Authorization) {
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `${jwtDefaultConfig.tokenType} ${token}`
  }

  return request
})

axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error

    if (response && response.status === 401) {
      if (jwtDefaultConfig.reloadOn401) {
        localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName)
        localStorage.removeItem(jwtDefaultConfig.userData)
        window.location.reload()
      }
    }
    return Promise.reject(error)
  },
)

Vue.config.productionTip = false
const helpersPlugin = {
  install() {
    Vue.helpers = Helper
    Vue.prototype.$helpers = Helper
    Vue.prototype.$manMudra = window.ManMudra
  },
}
Vue.use(helpersPlugin)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
