/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return window.ManMudra.getters.userInfo() && window.ManMudra.getters.accessToken()
}

export const getUserData = () => window.ManMudra.getters.userInfo()

export const objectToQuery = payload => {
  const searchParams = new URLSearchParams()
  Object.keys(payload).forEach(key => searchParams.append(key, payload[key]))
  return searchParams.toString()
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

export const goToPath = (that, name, params, query) => {
  const route = {
    name,
  }
  if (params) {
    route.params = params
  }
  if (query) {
    route.query = query
  }
  that.$router.push(route)
}

export const getUniqueNo = () => {
  const uniqueString = Date.now() + ((Math.random() * 100000).toFixed())
  const uniqueNumber = Number(uniqueString)
  return uniqueNumber
}

export const generateGuid = () => {
  let d = new Date().getTime()// Timestamp
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0// Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16// random number between 0 and 16
    if (d > 0) { // Use timestamp until depleted
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else { // Use microseconds since page-load if supported
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    // eslint-disable-next-line no-bitwise, no-mixed-operators
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}
