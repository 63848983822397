const useLocalApi = false

export default {
  // Endpoints
  loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
  groupsApi: !useLocalApi ? 'https://groups-api.manmudra.org' : 'https://localhost:44341/swagger/index.html',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  userData: 'userData',
  unionData: 'unionData',
  reloadOn401: true,
}
