import useJwt from '@/auth/jwt/useJwt'

window.ManMudra = {}
window.ManMudra.errorEnabled = true

window.ManMudra.validations = {
  get: () => document.querySelectorAll('.invalid-form .invalid-control').length,
  focus: () => {
    if (document.querySelectorAll('.invalid-form .invalid-control').length) {
      document.querySelectorAll('.invalid-form .invalid-control')[0].focus()
    }
  },
}

window.ManMudra.constants = {
  country: [
    {
      text: 'India',
      value: 'in',
    },
  ],
  internationalMember: [
    {
      text: 'YES',
      value: 'YES',
    },
    {
      text: 'NO',
      value: 'NO',
    },
  ],
  isActiveMember: [
    {
      text: 'ACTIVE',
      value: true,
    },
    {
      text: 'IN-ACTIVE',
      value: false,
    },
  ],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ].map((x, i) => ({
    id: i + 1,
    name: x,
  })),
  period: ['Monthly', 'Quaterly', 'Yearly'].map((x, i) => ({
    id: i + 1,
    name: x,
  })),
  genders: [
    {
      label: 'FEMALE',
      value: 'FEMALE',
      icon: '/images/members/female.svg',
    },
    {
      label: 'MALE',
      value: 'MALE',
      icon: '/images/members/male.svg',
    },
    {
      label: 'OTHER',
      value: 'OTHER',
      icon: '/images/members/otherSex.svg',
    },
    {
      label: 'UNDISCLOSED',
      value: 'UNDISCLOSED',
      icon: '/images/members/undisclosed.svg',
    },
  ],
  maritial: [
    {
      label: 'SINGLE',
      value: 'SINGLE',
      icon: '/images/members/single.svg',
    },
    {
      label: 'MARRIED',
      value: 'MARRIED',
      icon: '/images/members/married.svg',
    },
    {
      label: 'WIDOW',
      value: 'WIDOW',
      icon: '/images/members/widow.svg',
    },
    {
      label: 'DIVORCED',
      value: 'DIVORCED',
      icon: '/images/members/divorced.svg',
    },
    {
      label: 'DOMESTIC PARTNER',
      value: 'DOMESTIC PARTNER',
      icon: '/images/members/domesticPartners.svg',
    },
  ],
  category: [
    'SC',
    'ST',
    'OBC',
    'EWS',
    'MUSLIM',
    'CHRISTIAN',
    'GENERAL',
    'NOT LISTED',
  ].map(x => ({
    text: x,
    value: x,
  })),
  workTypes: [
    {
      text: 'NREGA',
      value: 'NREGA',
    },
    {
      text: 'CONSTRUCTION',
      value: 'CONSTRUCTION',
    },
    {
      text: 'RURAL AGRI',
      value: 'RURAL AGRI',
    },
    {
      text: 'FISH',
      value: 'FISH',
    },
    {
      text: 'DOMESTIC',
      value: 'DOMESTIC',
    },
    {
      text: 'HOUSEKEEPING',
      value: 'HOUSEKEEPING',
    },
    {
      text: 'MUNICIPALITIES',
      value: 'MUNCIPILITIES',
    },
    {
      text: 'PLATFORM',
      value: 'PLATFORM',
    },
  ],
  educations: [
    {
      text: 'NONE',
      value: 'NONE',
    },
    {
      text: '8TH',
      value: 'MIDDLE',
    },
    {
      text: '12TH',
      value: 'HSSC',
    },
    {
      text: 'GRADUATE',
      value: 'GRADUATE',
    },
    {
      text: 'POST-GRADUATE',
      value: 'POST GRADUATE',
    },
  ],
  eventStatus: [
    'ACTIVE',
    'CLOSED',
    'VOIDED',
  ].map(x => ({
    text: x,
    value: x,
  })),
  eventType: [
    'NONE',
    'TAKE',
    'GIVE',
  ].map(x => ({
    text: x,
    // eslint-disable-next-line no-nested-ternary
    value: x === 'TAKE' ? 4 : (x === 'GIVE' ? 0 : 2),
  })),
  status: [
    'ACTIVE',
    'SUSPENDED',
    'CANCELED',
    'CANCELED, NO RENEWAL',
  ].map(x => ({
    text: x,
    value: x,
  })),
  handicapOptions: [
    {
      text: 'YES',
      value: 'YES',
    },
    {
      text: 'NO',
      value: 'NO',
    },
  ],
  rationCardTypes: [
    {
      text: 'BPL',
      value: 'BPL',
    },
    {
      text: 'STATE BPL',
      value: 'STATE BPL',
    },
    {
      text: 'AAY BPL',
      value: 'AAY BPL',
    },
    {
      text: 'APL',
      value: 'APL',
    },
  ],
  wantsToLearnNewSkills: [
    {
      text: 'YES',
      value: 'YES',
    },
    {
      text: 'NO',
      value: 'NO',
    },
    {
      text: "DON'T KNOW",
      value: "DON'T KNOW",
    },
  ],
  transportMeans: [
    {
      text: 'WALK',
      value: 'WALK',
    },
    {
      text: 'BICYCLE',
      value: 'BICYCLE',
    },
    {
      text: 'BUS',
      value: 'BUS',
    },
    {
      text: 'MOTORBIKE',
      value: 'BIKE',
    },
    {
      text: 'METRO / TRAIN',
      value: 'TRAIN',
    },
    {
      text: 'CAR',
      value: 'CAR',
    },
  ],

}

window.ManMudra.actions = {
  toAmount: value => {
    if (!value) {
      // eslint-disable-next-line no-param-reassign
      value = 0
    }
    return parseFloat(value).toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
    }).split('.')[0].replace('₹', '')
  },
  logout: () => {
    localStorage.removeItem(useJwt.jwtConfig.userData)
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  },
  cloneDeep: data => {
    if (!data) return data
    return JSON.parse(JSON.stringify(data))
  },
  debounce: (func, wait) => {
    let timeout

    // eslint-disable-next-line func-names
    return function () {
      const context = this
      // eslint-disable-next-line prefer-rest-params
      const args = arguments

      clearTimeout(timeout)

      timeout = setTimeout(() => {
        func.apply(context, args)
      }, wait)
    }
  },
}

window.ManMudra.getters = {
  unionData: () => {
    const data = localStorage.getItem(useJwt.jwtConfig.unionData)
    if (data) {
      try {
        return JSON.parse(data)
      } catch (error) {
        return null
      }
    }
    return null
  },
  isSuperAdmin: () => {
    const userInfo = window.ManMudra.getters.userInfo()
    if (userInfo && Array.isArray(userInfo.roles)) {
      return userInfo.roles.includes('SuperAdmin') || userInfo.roles.includes('DevUser')
    }
    return false
  },
  userInfo: () => {
    const data = localStorage.getItem(useJwt.jwtConfig.userData)
    if (data) {
      try {
        return JSON.parse(data)
      } catch (error) {
        return null
      }
    }
    return null
  },
  accessToken: () => {
    const data = localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
    if (data) {
      return data
    }
    return null
  },
}
