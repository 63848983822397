<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <scroll-to-top />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import ScrollToTop from './@core/components/scroll-to-top/ScrollToTop.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.meta.pageTitle) {
          document.title = `${this.$route.meta.pageTitle} - ManMudra`
        } else {
          document.title = 'ManMudra'
        }
      },
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 1500,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>

<style lang="scss">
.input-common .input-group-text {
  background: #ebebeb;
}

@media only screen and (max-width: 767px) {
  .d-none-mobile {
    display: none !important;
  }

  [dir] body .grid-main-action-button img,
  body .grid-main-action-button img {
    margin-right: 2px !important;
  }

  .vue-good-datatable-view .card-body {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .custom-search.d-flex.justify-content-end.grid-main-action-button {
    width: 55%;
  }

  .vgt-wrap .vgt-responsive {
    overflow: auto;
    overflow-x: auto;
  }

  body .vue-good-datatable-view .grid-main-action-button {
    display: block !important;
    text-align: right;
    margin-bottom: 10px;
  }

  .btn-mobile-adjust .grid-main-action-button button {
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style lang="scss">
.vue-good-datatable-view {
  .header-name-columns {
    display: none;
  }

  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    .header-name-columns {
      display: block;
      min-width: 120px;
      width: 120px;
      max-width: 120px;
    }

    .card-body {
      padding: 0;
    }

    .row-inner-tables {
      display: flex;
      justify-content: space-between;
    }

    .vgt-table.bordered td {
      padding: 5px !important;
    }

    .vgt-table.bordered td:first-child {
      background-color: #ebe9f1;
      color: #000;
      border: 0;
    }

    .row-inner-tables span {
      word-break: break-all;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }
  }
}
</style>
