import Vue from 'vue'
import VueRouter from 'vue-router'
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app'
import { isUserLoggedIn } from '@/auth/utils'
import groups from './routes/groups'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        canActivate: true,
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        pageTitle: 'Login',
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    ...groups,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  hideLoader()
})

router.beforeEach((to, from, next) => {
  const { canActivate } = to.meta
  showLoader()
  if (canActivate) {
    const isLoggedIn = isUserLoggedIn()
    if (!isLoggedIn) {
      next({ path: '/login', query: { returnUrl: to.path } })
      hideLoader()
    }
  }
  next()
})

export default router
