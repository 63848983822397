<script>
import { goToPath } from './auth/utils'

export default {
  toFixed(value, points) {
    if (!value) return value
    return parseFloat(parseFloat(value).toFixed(points))
  },
  goTo: (that, path, params, queryString) => {
    goToPath(that, path, params, queryString)
  },
  formatDate: date => {
    if (!date) return ''
    const d = new Date(date)
    const mm = d.getMonth()
    const dd = d.getDate()
    const yy = d.getFullYear()
    return `${yy}-${mm.toString().padStart(2, '0')}-${dd
      .toString()
      .padStart(2, '0')}`
  },
  formatBackendDate: date => {
    if (!date) return ''
    const d = new Date(date)
    const mm = d.getMonth() + 1
    const dd = d.getDate()
    const yy = d.getFullYear()
    return `${yy}-${mm.toString().padStart(2, '0')}-${dd
      .toString()
      .padStart(2, '0')}`
  },
  focusNext: e => {
    const inputs = Array.from(
      e.target.form.querySelectorAll(
        'input[type="text"], input[type="number"], input[type="date"], input[type="password"], button',
      ),
    )
    const index = inputs.indexOf(e.target)

    if (index < inputs.length && inputs[index + 1]) {
      inputs[index + 1].focus()
    }
  },
  getIcons(type = '') {
    // eslint-disable-next-line global-require
    if (type === 'delete') { return require('@/assets/images/app-icons/delete_grid.png') }
    // eslint-disable-next-line global-require
    if (type === 'edit') { return require('@/assets/images/app-icons/edit_grid.png') }
    // eslint-disable-next-line global-require
    if (type === 'status') { return require('@/assets/images/app-icons/status_grid.png') }
    // eslint-disable-next-line global-require
    if (type === 'update') { return require('@/assets/images/app-icons/update_grid.png') }
    // eslint-disable-next-line global-require
    if (type === 'copy') { return require('@/assets/images/app-icons/copy.png') }
    return type
  },
}
</script>
