export default [
  {
    path: '/groups/unions',
    name: 'group-union-list',
    component: () => import('@/views/groups/unions/List.vue'),
    meta: {
      pageTitle: 'Unions',
      canActivate: true,
      previous: 'dashboard',
      showUnionSelection: false,
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Unions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/unions/add',
    name: 'group-union-add',
    showUnionSelection: false,
    component: () => import('@/views/groups/unions/Manage.vue'),
    meta: {
      pageTitle: 'Unions',
      canActivate: true,
      previous: 'group-union-list',
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Unions',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/unions/edit/:unionId',
    name: 'group-union-edit',
    component: () => import('@/views/groups/unions/Manage.vue'),
    meta: {
      pageTitle: 'Unions',
      canActivate: true,
      previous: 'group-union-list',
      showUnionSelection: false,
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Unions',
          active: false,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/members',
    name: 'group-members-list',
    component: () => import('@/views/groups/members/List.vue'),
    meta: {
      pageTitle: 'Members',
      canActivate: true,
      previous: 'dashboard',
      showUnionSelection: true,
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Members',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/members/add',
    name: 'group-member-add',
    component: () => import('@/views/groups/members/Manage.vue'),
    meta: {
      pageTitle: 'Members',
      canActivate: true,
      showUnionSelection: true,
      actionType: 'add-member',
      previous: 'group-members-list',
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Members',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/non-members/add',
    name: 'group-member-non-add',
    component: () => import('@/views/groups/members/Manage.vue'),
    meta: {
      pageTitle: 'Non-Members',
      canActivate: true,
      showUnionSelection: true,
      previous: 'group-members-list',
      actionType: 'add-non-member',
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Non-Members',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/members/edit/:memberId',
    name: 'group-member-edit',
    component: () => import('@/views/groups/members/Manage.vue'),
    meta: {
      pageTitle: 'Members',
      canActivate: true,
      showUnionSelection: true,
      previous: 'group-members-list',
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Members',
          active: false,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/event',
    name: 'group-event-list',
    component: () => import('@/views/groups/event/List.vue'),
    meta: {
      pageTitle: 'Events',
      canActivate: true,
      previous: 'dashboard',
      showUnionSelection: true,
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/event/add',
    name: 'group-event-add',
    component: () => import('@/views/groups/event/Manage.vue'),
    meta: {
      pageTitle: 'Event Add',
      canActivate: true,
      previous: 'group-event-list',
      showUnionSelection: true,
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Members',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/event/edit/:eventId',
    name: 'group-event-edit',
    component: () => import('@/views/groups/event/Manage.vue'),
    meta: {
      pageTitle: 'Event Edit',
      canActivate: true,
      previous: 'group-event-list',
      showUnionSelection: true,
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Members',
          active: false,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/bank',
    name: 'group-bank-list',
    component: () => import('@/views/groups/bank/List.vue'),
    meta: {
      pageTitle: 'Banks',
      canActivate: true,
      previous: 'dashboard',
      showUnionSelection: true,
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Banks',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/bank/add',
    name: 'group-bank-add',
    component: () => import('@/views/groups/bank/Manage.vue'),
    meta: {
      pageTitle: 'Bank Add',
      canActivate: true,
      previous: 'group-bank-list',
      showUnionSelection: true,
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Banks',
          active: true,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/bank/edit/:bankId',
    name: 'group-bank-edit',
    component: () => import('@/views/groups/bank/Manage.vue'),
    meta: {
      pageTitle: 'Bank Edit',
      canActivate: true,
      previous: 'group-bank-list',
      showUnionSelection: true,
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Banks',
          active: true,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/transactions',
    name: 'group-transaction-list',
    component: () => import('@/views/groups/transaction/List.vue'),
    meta: {
      pageTitle: 'Transactions',
      canActivate: true,
      previous: 'dashboard',
      showUnionSelection: true,
      breadcrumb: [
        {
          text: 'Groups',
          active: false,
        },
        {
          text: 'Transactions',
          active: true,
        },
      ],
    },
  },
]
